import React from "react";
import { Link } from "react-router-dom";
import bgRegister from "../../assets/imgs/bg_register.jpg";
import LogoCompany from "../../assets/imgs/LogoLigaPremier.png";
import "./register.scss";
import { ecommerceService } from '../../services/ecommerce.service'
import swal from "sweetalert"
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache"

export function Membership(props) {
    const companyLogo = useSelector(state => state.userSettings.companyLogo);
    const model = props.location.state;

    const payMembership = () => {
        const request = {
            UserId: model.userId,
            PaymentMethodId: 9,
            Products: [{
                ProductId: model.productId,
                Cant: 1
            }]
        };

        ecommerceService.payMembership(request)
            .then(result => {
                if (result != undefined) {
                    window.location.href = result
                } else {
                    swal({ text: "Ocurrió un error al abrir método de pago", icon: "error" })
                }
            });
    }

    return (
        <section className="wrapper_register">
            <img
                className="bg_register"
                src={bgRegister}
                alt="Registrate en Kuimby Social"
            />
            <div className="container container_register">
                <div className="drawer_card_register">
                    <div className="box_brand_register">
                        <div>
                            <h5 className="m-0 text c_primary">Obtén tu </h5>
                            <h2 className="m-0 text fnt_bold c_primary">Membresía</h2>
                        </div>
                        <ImgCache src={companyLogo ? companyLogo : LogoCompany} alt="Kuimby Social" />
                    </div>
                    <hr />

                    <div className="box_form_register">
                        <div className="box_form_membership">
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    <h4 className=" text-center text mb-0">
                                        Hola, <span className="c_primary">{model && model.userName}</span>{" "}
                                    </h4>
                                    <h2 className="ttl_membership text-center text fnt_bold line">
                                        ¡Estás a punto de descubrir un mundo nuevo!
                                    </h2>
                                    <p className="paragraph ">
                                        Al adquirir tu {model && model.productName} comenzarás a
                                        disfrutar de todos los beneficios que tiene para ti.
                                    </p>
                                    <p className="text fnt_bold">
                                        Costo de membresía:{" "}
                                        <span className="c_primary">${model && model.productPrice}</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="box_form_btns text-right mt-2">
                            <Link to="/login" className="btn secundary ">
                                Cancelar
                            </Link>
                            {model && <button type="button" className="btn primary" onClick={payMembership}>
                                Obtener membresía
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
