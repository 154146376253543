import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import Swal from "sweetalert2";
import useInputForm from '../../../components/CustonHooks/FormHook'
import { PDFViewer } from './PDFViewer'

import { useLegalDocumentService } from '../../../services/legalDocument.service'
import { legalDocumentTypeService } from '../../../services/legalDocumentType.service'

export function EditLegalDocument(props) {
    const onSave = () => {
      let formData = new FormData();
      formData.append("LegalDocumentTypeId", parseInt(inputs.legalDocumentTypeId));
      formData.append("File", inputs.file);

      if (inputs.legalDocumentId > 0){
        formData.append("LegalDocumentId", parseInt(inputs.legalDocumentId));
        useLegalDocumentService.updateLegalDocument(formData)
          .then((result) => {
            if (result != null)
              Swal.fire({text: "Se ha actualizado el documento legal", icon: "success", showCloseButton: true, allowOutsideClick: false, allowEscapeKey: false})
                .then(() => {
                  if (props.onSave)
                    props.onSave();
                });
          });
      }
      else
        useLegalDocumentService.createLegalDocument(formData)
          .then((result) => {
            if (result != null)
              Swal.fire({text: "Se ha creado el documento legal", icon: "success", showCloseButton: true, allowOutsideClick: false, allowEscapeKey: false})
                .then(() => {
                  if (props.onSave)
                    props.onSave();
                });
          });
    };
    const handleOnCancel = () => {
        if (props.onCancelEdit)
            props.onCancelEdit();
    };

    const [options, setOptions] = useState([]);
    const [legalDocItem, setLegalDocItem] = useState({ legalDocumentId: 0, legalDocumentTypeId: 0, file: null });
    const { handleSubmit, handleInputChange, inputs, handleInputFileChange } = useInputForm(onSave, legalDocItem);
    const isEdit = legalDocItem.legalDocumentId > 0;
    const enableSaveBtn = inputs.legalDocumentTypeId != 0 && inputs.file != null;

    useEffect(() => {
      legalDocumentTypeService.getAll().then((result) => {
        if (!result) return;
        setOptions(result.map((x) => ({ value: x.legalDocumentTypeId, name: x.name })));
      });
    }, []);

    useEffect(() => {
      let newP = {};
      if (props.currentLegalDocument) {
        newP = {
          legalDocumentId: props.currentLegalDocument.legalDocumentId,
          legalDocumentTypeId: props.currentLegalDocument.legalDocumentType,
          file: props.currentLegalDocument.file };
        setLegalDocItem(newP);
      }
    }, [props.currentLegalDocument]);
    
    useEffect(() => {
      if (options?.length > 0){
        if (legalDocItem.legalDocumentId > 0){
          let type = options.find(x => x.name == legalDocItem.legalDocumentTypeId);
          legalDocItem.legalDocumentTypeId = type.value;
          document.getElementById("legalDocumentTypeId").value = type.value;
        }
      }
    }, [options]);

    return (
        <div className="row">
            <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                    <fieldset className='mt-4 mb-5'>
                        <legend className="text fnt_medium">
                            {isEdit ? "Editar documento legal": "Nuevo documento legal"}
                        </legend>
                        <div className="col-md-12">
                            <div className="row justify-content-between">
                                <div>
                                  <div className="row">
                                      <div className="col-md-8">
                                          <input type="hidden" name="legalDocumentId" onChange={handleInputChange} value={inputs.legalDocumentId} />
                                          <div className="form-group">
                                              <label htmlFor="legalDocumentTypeId">Tipo de documento</label>
                                              <select
                                                className="form-control"
                                                id="legalDocumentTypeId"
                                                name="legalDocumentTypeId"
                                                onChange={handleInputChange}
                                                disabled={isEdit}
                                              >
                                                <option key={0} value={0}> Selecciona una opción</option>
                                                {
                                                  options.map(item => <option key={item.value} value={item.value}> {item.name}</option>)
                                                }
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-8">
                                          <label htmlFor="file">Archivo PDF</label>
                                          <input type="file" id="file" name="file" onChange={handleInputFileChange} />
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-8">
                                          <br/>
                                          <button className="btn minimum ml-1 mr-1 secundary" type="button" onClick={handleOnCancel}>Cancelar</button>
                                          <button className="btn minimum primary ml-1 mr-1" type="submit" disabled={!enableSaveBtn}>Guardar</button>
                                      </div>
                                  </div>
                                </div>
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="pdfViewer"> Previsualización</label>
                                            <PDFViewer id="pdfViewer" file={inputs.file} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}   