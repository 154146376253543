import React from "react";
import { Link } from "react-router-dom";
import bgRegister from "../../assets/imgs/bg_register.jpg";
import LogoCompany from "../../assets/imgs/LogoLigaPremier.png";
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache";
import "./successfulReg.scss";

export function SuccessfulReg() {
  const companyLogo = useSelector((state) => state.userSettings.companyLogo);

  return (
    <section className="wrapper_register successful_reg">
      <img
        className="bg_register"
        src={bgRegister}
        alt="Registrate en Kuimby Social"
      />

      <div className="container container_register">
        <div className="drawer_card_register">
          <div className="box_brand_register text-center">
            <ImgCache
              src={companyLogo ? companyLogo : LogoCompany}
              alt="Logo oficial Kuimby Social"
            />
          </div>
          <div className="content_successful">
            <div className="ico_ok">
              <ion-icon name="checkmark-circle-outline"></ion-icon>
            </div>
            <h5 className=" text fnt_medium ">REGISTRO EXITOSO</h5>
            <h1 className="ttl_form text fnt_bold ttls_black c_primary">
              ¡Ya eres parte de nuestra comunidad!
            </h1>
            <h4>
              Te enviamos un link de confirmación a tu correo electrónico, por favor verifícalo para iniciar sesión
            </h4>
            <hr />
            <div className="text-center mb-4">
              <Link className="btn secundary" to='/login'>Iniciar sesión</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
