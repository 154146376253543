import React, { Fragment, useEffect, useState } from "react";
import { messageService } from "../../services/message.service";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { DropDownDate, DropDownStatus } from "./dropDownsMessage";
import { useSelector } from "react-redux";
import { messagePrivilegeService } from "../../services/messagePrivilige.service";

export default function SentMessage(props) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [userMessages, setUserMessages] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    title: "Descendente",
    value: "desc",
  });
  const [statusFilter, setStatusFilter] = useState({
    title: "Todos",
    value: "",
  });
  const [isSender, setIsSender] = useState(false);
  const history = useHistory();
  const readMessage = (id) => history.push(`/messages/${id}`);

  useEffect(() => {
    getMessages();
  }, [dateFilter, statusFilter]);

  useEffect(() => {
    getUserPermissions();
  }, [roleName]);

  const getMessages = () => {
    messageService
      .getMessage(1, dateFilter.value, statusFilter.value)
      .then((data) => setUserMessages(data));
  };

  const getUserPermissions = () => {
    messagePrivilegeService.getUserPrivilege().then((data) => {
      if (roleName == "Recursos Humanos" || data) {
        setIsSender(true);
        return true;
      } else {
        setIsSender(false);
        return false;
      }
    });
  };

  const deleteMessage = (id) =>
    messageService.deleteMessage(id).then(getMessages);

  return (
    <Fragment>
      <div className="mb-4 divtop">
        <div className="box_ttl_opt mb-0">
          <h3 className="mb-0 text fnt_medium">Avisos enviados</h3>
          <div className="box_actions">
            {isSender && (
              <button
                type="button"
                className="btn secundary"
                onClick={() => {
                  props.setIsActive(5);
                  props.setDraft({});
                }}
              >
                <i className="fas fa-plus"></i> Avisos nuevo
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="box_filter_messg">
        <DropDownStatus
          title={statusFilter.title}
          setFilter={setStatusFilter}
        />
        <DropDownDate title={dateFilter.title} setFilter={setDateFilter} />
      </div>
      <div className="table-responsive">
        <table className="wrapper_table table table-hover table-sm">
          <thead>
            <tr>
              <th className="text fnt_medium" scope="col">
                Asunto
              </th>
              <th className="text fnt_medium" scope="col">
                Fecha
              </th>
              <th className="text fnt_medium" scope="col">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {userMessages.map((message, index) => (
              <tr
                key={index}
                className={`tr_msg ${
                  message.messageDetail && message.messageDetail.isRead
                    ? "is_read"
                    : ""
                }`}
              >
                <td
                  className="link_data td_user"
                  onClick={() => readMessage(message.message.messageId)}
                >
                  {message.message.subject}
                </td>
                <td>
                  <Moment format="DD/MM/YYYY">
                    {message.message.createDate}
                  </Moment>
                </td>
                <td>
                  {/*<span className="icon_tables" onClick={() => deleteMessage(message.message.messageId)}>
                    <i className="far fa-trash-alt"></i>
                  </span>*/}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
