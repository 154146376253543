import React, { useEffect, useState } from "react";
import "moment/locale/es";
import { policyViewService } from "../../services/policyView.service";
import { useSelector } from "react-redux";
import moment from "react-moment";
import SearchCompanyPost from './../../backoffice/ourCompanyAdmin/searchCompanyPost';

export function BoxListPolicy() {
  const [dataGrid, setDataGridPending] = useState([]);
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [dataGridCompleted, setDataGridCompleted] = useState([]);



  async function getPendingPolicy() {
    policyViewService.getPending().then((result) => {
      if (result) {
        setDataGridPending(result);
        console.log(dataGrid)
      }
    });
  }

  async function getPolicyCompleted() {
    policyViewService.getComplete().then((result) => {
      if (result) {
        setDataGridCompleted(result);
        console.log(dataGrid)
      }
    });
  }

  useEffect(() => {
    getPendingPolicy();
    //GetUserProfilePolicy();
    getPolicyCompleted();
  }, []);

  return (
    <>
      <h4 className="text-muted text fnt_medium">¡Consúltalos aquí!</h4>
      <div className="box_list_audience row">
        {/*<article className="col-sm-6 col-lg-4 mb-4">
          <div className="card shadows">
            <div className="box_img_stories left_image">
              <a
                className="item_audience"
                href="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/politics/Red%20Voucher%20Online%202022.pdf"
                target="_blank"
              >
                <div
                  className="boxImage"
                  style={{
                    backgroundImage:
                      "url(https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/politics/Red%20Voucher%20Online.jpg)",
                  }}
                ></div>
              </a>
            </div>
            <div className="content_info_policy">
              <h5 className="card-title mt-2 mb-0 text fnt_medium">
                Conoce RedVoucherOnline
              </h5>
              <p className="p_paragraph">
                Diversión y entretenimiento sin límite. Encuentra a tus marcas
                favoritas y sorpréndete.
              </p>
            </div>
          </div>
        </article>*/}

        {dataGrid.map((policy) => (
          <article className="col-sm-6 col-lg-4 mb-4" key={policy.data.policyId}>
            <div className="card shadows">
              <div className="box_img_stories left_image">
                <a
                  className="item_audience"
                  href={policy.data.url}
                  target="_blank"
                >
                  <div
                    className="boxImage"
                    style={{
                      backgroundImage:
                        'url('+policy.files[0]?.path+')',
                    }}
                  ></div>
                </a>
              </div>
              <div className="content_info_policy">
                <h5 className="card-title mt-2 mb-0 text fnt_medium">
                  {policy.data.title}
                </h5>
                {/* <div class="ttls_direct card-text mb-1 col c_primary name__categ">
                  <span className="itemcat">
                    {policy.data.policyType}
                  </span>
                </div> */}
                <p className="p_paragraph">{policy.data.description}</p>
              </div>
            </div>
          </article>
        ))}
      </div>
    </>
  );
}
