import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/es";
import { BoxListPolicy } from "./boxListPolicy";
import { SideAdvertising } from "../../components/sideAdvertising/sideAdvertising";
import "./policyView.scss";
import { policyViewService } from "../../services/policyView.service";
import { useSelector } from "react-redux";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";

export function PolicyView(props) {
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  return (
    <section className="wrapper_surveys wrapper_policy padd">
      <div className="content_surveys w-100">
        <div className="row align-items-center mb-4">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">Reglamentos</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date.now()}
              </Moment>
            </span>
          </div>
        </div>
        <p className="drawer_reg_sex">
          Te invitamos a conocer los reglamentos de la liga.
        </p>

        <AdvertisingSection HolType={8} />

        {/* <div className="content_ttls">
          <div className="minw">
            <h4 className="text fnt_medium ttl mb-3 text-white">
                Accede a nuestro contenido multimedia
            </h4>
            <p className="text fnt_medium">
                Próximos eventos.
            </p>
          </div>
          <div className="box_img_surveys">
            <img src={PicturePolitics} alt="Capacitación" />
          </div>
        </div> */}

        <BoxListPolicy />
      </div>
    </section>
  );
}
