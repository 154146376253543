import React, { useState } from "react";
import { Link } from "react-router-dom";
import bgRegister from "../../assets/imgs/bg_register.jpg";
import LogoCompany from "../../assets/imgs/LogoLigaPremier.png";
import "./register.scss";
import { userService } from "../../services/user.service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaPage1, schemaPage2, schemaPage3 } from "./registerSchema";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache";
import { Legals } from "../../components/legals/legals";
import moment from "moment";
import { newAccountService } from "../../services/newAccountService.service";
import { fontStyle } from "@mui/system";

const Page1 = ({ register, setValue, errors }) => {
  const [showPass, setShowPass] = useState("password");
  const [showIco, setShowIco] = useState("fa-eye-slash");
  const [maritalStatusCatalog, setMaritalStatus] = useState([]);
  const [nacionalityCatalog, setNacionalityCatalog] = useState([]);

  const setInputPass = () => {
    setShowPass(showPass === "password" ? "text" : "password");
    setShowIco(showIco === "fa-eye-slash" ? "fa-eye" : "fa-eye-slash");
  };

  const getMaritalStatus = () => {
    newAccountService
      .getMaritalStatusCatalog()
      .then((cat) => {
        setMaritalStatus(cat);
        register("MaritalStatusTypeId", { value: cat[0].maritalStatusTypeId });
      })
      .catch((err) => alert(err));
  };

  const getNacionality = () => {
    newAccountService
      .getNacionalityCatalog()
      .then((nac) => {
        setNacionalityCatalog(nac);
        register("NationalityTypeId", { value: nac[0].nationalityTypeId });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMaritalStatus();
    getNacionality();
  }, []);

  return (
    <div className="box_form_user">
      <div className="row">
        <div className="col-12">
          <h5 className="text fnt_medium text-center mb-4">Datos de usuario</h5>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <label htmlFor="firstName">Nombre(s)</label>
            <input
              type="text"
              className={`form-control ${errors.firstName && "is-invalid"}`}
              placeholder="Nombre(s)"
              {...register("firstName")}
            />
            <div className="invalid-feedback">{errors.firstName?.message}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="middleName">Apellido Paterno</label>
            <input
              type="text"
              className={`form-control ${errors.middleName && "is-invalid"}`}
              placeholder="Apellido Paterno"
              {...register("middleName")}
            />
            <div className="invalid-feedback">{errors.middleName?.message}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="lastName">Apellido Materno</label>
            <input
              type="text"
              className={`form-control ${errors.lastName && "is-invalid"}`}
              placeholder="Apellido Materno"
              {...register("lastName")}
            />
            <div className="invalid-feedback">{errors.lastName?.message}</div>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-6">
          <div className="form-group">
            <div>Sexo</div>
            <div
              className={`custom-control custom-radio custom-control-inline ${
                errors.gender && "is-invalid"
              }`}
            >
              <input
                type="radio"
                id="genderM"
                className="custom-control-input"
                value="M"
                {...register("gender")}
              />
              <label className="custom-control-label" htmlFor="genderM">
                Masculino
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="genderF"
                className="custom-control-input"
                value="F"
                {...register("gender")}
              />
              <label className="custom-control-label" htmlFor="genderF">
                Femenino
              </label>
            </div>
            <p className="drawer_reg_sex">
              Sabemos que es {new Date().getFullYear()} pero de todos modos
              debemos pedir este dato
            </p>
            <div className="is-invalid invalid-feedback">
              {errors.gender?.message}
            </div>
          </div>
        </div> */}
        {/* <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label for="MaritalStatusTypeId">Estado civil</label>
            <select
              className="form-control"
              id="MaritalStatusTypeId"
              name="MaritalStatusTypeId"
              {...register("MaritalStatusTypeId")}
            >
              {maritalStatusCatalog.map((x, index) => (
                <option value={x.maritalStatusTypeId} selected={index === 0}>
                  {x.description}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label for="NationalityTypeId">Nacionalidad</label>
            <select
              className="form-control"
              id="NationalityTypeId"
              name="NationalityTypeId"
              {...register("NationalityTypeId")}
            >
              {nacionalityCatalog.map((x, index) => (
                <option value={x.nationalityTypeId} selected={index === 0}>
                  {x.description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="birthday">Fecha de Nacimiento</label>
            <input
              type="date"
              className={`form-control ${errors.birthday && "is-invalid"}`}
              {...register("birthday")}
              min="1900-01-01"
              placeholder="01-01-2000"
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
            <div className="invalid-feedback">{errors.birthday?.message}</div>
            <p className="drawer_reg_sex w-100 mt-2">
              Queremos felicitarte cada año.
            </p>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="mobile">Teléfono celular</label>
            <input
              type="text"
              className={`form-control ${errors.mobile && "is-invalid"}`}
              placeholder="5555555555"
              maxLength={10}
              minLength={10}
              onKeyPress={(e) => {
                e = e || window.event;
                var charCode = e.keyCode || e.which;
                var charStr = String.fromCharCode(charCode);
                if (isNaN(charStr)) e.preventDefault();
              }}
              onKeyDown={(e) => {}}
              {...register("mobile")}
              register={true}
            />
            <div className="invalid-feedback">{errors.mobile?.message}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="mobile">Confirmar teléfono celular</label>
            <input
              type="text"
              className={`form-control ${errors.mobile && "is-invalid"}`}
              placeholder="5555555555"
              maxLength={10}
              minLength={10}
              onKeyPress={(e) => {
                e = e || window.event;
                var charCode = e.keyCode || e.which;
                var charStr = String.fromCharCode(charCode);
                if (isNaN(charStr)) e.preventDefault();
              }}
            />
          </div>
        </div>
        {/* <div className="col-sm-6 col-md-3 col-lg-4">
          <div className="form-group">
            <label htmlFor="phoneNumber">
              Teléfono de casa <strong>(Opcional)</strong>
            </label>
            <input
              type="text"
              className={`form-control `}
              placeholder="5555555555"
              maxLength={10}
              minLength={10}
              onKeyPress={(e) => {
                e = e || window.event;
                var charCode = e.keyCode || e.which;
                var charStr = String.fromCharCode(charCode);
                if (isNaN(charStr)) e.preventDefault();
              }}
              {...register("phoneNumber")}
            />
             <div className="invalid-feedback">{errors.mobile?.message}</div> 
          </div>
        </div> */}
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <h5 className="text fnt_medium text-center mb-4">Domicilio</h5>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-4">
          <p className="drawer_reg_sex w-100 mt-2">
            Buscamos llevar PrideMx a cada rincón.
          </p>
          <div className="form-group">
            <label htmlFor="PostalCode">Código postal</label>
            <input
              type="number"
              min={0}
              max={99999}
              className={`form-control ${errors.PostalCode && "is-invalid"}`}
              placeholder="Ejemplo:12345"
              // required={true}
              {...register("PostalCode")}
            />
            <div className="invalid-feedback">{errors.PostalCode?.message}</div>
          </div>
          <div className="invalid-feedback">{errors.PostalCode?.message}</div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <h5 className="text fnt_medium text-center mb-4">
            Datos de la cuenta
          </h5>
          <div className="box_animate_text">
          <p className="drawer_reg_sex">
          ¡No olvides verificar tu cuenta! Revisa bandeja de entrada o spam de tu
            correo electrónico registrado.
          </p>
        </div>          

        </div>

        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label htmlFor="email">Correo electrónico</label>
            <input
              type="email"
              className={`form-control ${errors.email && "is-invalid"}`}
              aria-describedby="emailHelp"
              placeholder="correo@dominio.com"
              // required={true}
              {...register("email")}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="form-group box_input_icons">
            <label htmlFor="password">Contraseña</label>
            <input
              type={showPass}
              className={`form-control ${errors.password && "is-invalid"}`}
              // required={true}
              aria-describedby="passHelp"
              placeholder="Contraseña"
              {...register("password")}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
            <span className="ico_show_pass" onClick={setInputPass}>
              <i className={`fas ${showIco}`}></i>
            </span>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="form-group box_input_icons">
            <label htmlFor="confirmPassword">Confirmar contraseña</label>
            <input
              type={showPass}
              className={`form-control ${
                errors.confirmPassword && "is-invalid"
              }`}
              aria-describedby="passConfHelp"
              placeholder="Confirmar Contraseña"
              // required={true}
              {...register("confirmPassword")}
            />
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>
            <span className="ico_show_pass" onClick={setInputPass}>
              <i className={`fas ${showIco}`}></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Page2 = ({ register, errors }) => {
  const [stateCatalog, setStateStatus] = useState([]);

  const getState = () => {
    newAccountService.getStateCatalog().then((state) => {
      setStateStatus(state);
      register("CountryStateTypeId", { value: state[0].countryStateTypeId });
    });
  };
  useEffect(() => {
    getState();
  }, []);

  return (
    <div className="box_form_user">
      <div className="row mb-4">
        <div className="col-12">
          <h5 className="text fnt_medium text-center mb-4">Domicilio</h5>
          <p className="paragraph text-center">
            No queremos stalkearte, solo queremos llevar PrideMx a cada rincón.
          </p>
          <p className="drawer_reg_sex">
            La ubicación nos permite ofrecerte diversidad de servicios. Si
            tienes alguna duda, puedes revisar nuestro Aviso de Privacidad.
          </p>
        </div>
      </div>
      <div className="row box_cp">
        <div className=" col-6 ">
          <div className="form-group">
            <label htmlFor="PostalCode">Código postal</label>
            <input
              type="number"
              min={0}
              max={99999}
              className={`form-control ${errors.PostalCode && "is-invalid"}`}
              placeholder="12345"
              // required={true}
              {...register("PostalCode")}
            />
            <div className="invalid-feedback">{errors.PostalCode?.message}</div>
          </div>
          <div className="invalid-feedback">{errors.PostalCode?.message}</div>
        </div>
        <div className="col-5">
          <button className="btn secundary mt-4 minimum">
            <i class="fa-solid fa-magnifying-glass"></i>{" "}
            <span className="d-none d-sm-block">Buscar</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label htmlFor="Street">Calle</label>
            <input
              type="text"
              className={`form-control ${errors.Street && "is-invalid"}`}
              placeholder="Ej: Av. Los Olivos"
              // required={true}
              {...register("Street")}
            />
            <div className="invalid-feedback">{errors.Street?.message}</div>
          </div>
          <div className="invalid-feedback">{errors.Street?.message}</div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="form-group">
            <label htmlFor="ExteriorNumber">Núm. exterior</label>
            <input
              type="number"
              min={0}
              className={`form-control `}
              required={true}
              {...register("ExteriorNumber")}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="form-group">
            <label htmlFor="InnerNumber">Núm. interior</label>
            <input
              type="text"
              className={`form-control `}
              {...register("InnerNumber")}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label for="CountryStateTypeId">Estado</label>
            <select
              className={`form-control`}
              id="CountryStateTypeId"
              name="CountryStateTypeId"
              {...register("CountryStateTypeId")}
            >
              {stateCatalog.map((x, index) => (
                <option value={x?.countryStateTypeId} selected={index === 0}>
                  {x?.description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label htmlFor="Colony">Colonia</label>
            <input
              type="text"
              className={`form-control`}
              id="Colony"
              {...register("colony")}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="form-group">
            <label>Delegación/Municipio</label>
            <input
              type="text"
              id="municipio"
              {...register("town")}
              className={`form-control`}
              required={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Page3 = ({ register, getValues, setValue, errors }) => {
  return (
    <div className="box_form_user">
      <div className="row">
        <div className="col-12">
          <h5 className="text fnt_medium text-center mb-4">
            ¿Eres mayor de 18 años?
          </h5>
          <p className="paragraph text-center">
            Queremos que te sientas segurx. Por favor, compártenos tu RFC.
          </p>
          <p className="drawer_reg_sex">
            Para fortalecer nuestra comunidad la veracidad de los datos es
            importante. El RFC nos permite lograr este objetivo.
          </p>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label>RFC Iniciales </label>
            <input
              type="text"
              className={`form-control`}
              minLength={3}
              maxLength={4}
              required={true}
              {...register("iniciales")}
              onChange={(event, value) => {
                setValue("iniciales", event.target.value.toUpperCase());
              }}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label>Fecha</label>
            <input
              type="text"
              defaultValue={getValues("birthday")
                .replace(/-/g, "")
                .substring(2)}
              //Quito guiones y los primeros 2 dígitos del año
              className={`form-control`}
              minLength={6}
              maxLength={6}
              required={true}
              {...register("fechanac")}
            />
            <div className="invalid-feedback">{errors.fechanac?.message}</div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className="form-group">
            <label>Homoclave</label>
            <input
              type="text"
              className={`form-control`}
              required={true}
              minLength={3}
              maxLength={3}
              {...register("homoclave")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export function Register(props) {
  const companyLogo = useSelector((state) => state.userSettings.companyLogo);
  const accountSettings = useSelector((state) => state.accountSettings);
  // const companyID = useSelector(state => state.userSettings.companyId)
  const [activePage, setActivePage] = useState(1);
  const [schema, setSchema] = useState(schemaPage1);

  useEffect(() => {
    // form validation rules
    if (activePage == 1) setSchema(schemaPage1);
    else {
      if (activePage == 2) {
        setSchema(schemaPage3);
      }

      // }else setSchema(schemaPage3);
    }
  }, [activePage]);

  // get functions to build form with useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    //if (activePage < 3) nextPage();
    //else registerUser(data);
    if (accountSettings.activateRegistration) registerUser(data);
  };

  const registerUser = (data) => {
    data.CompanyId = process.env.REACT_APP_COMPANY;
    data.RFC = data.iniciales + data.fechanac;
    data.NationalityTypeId =
      data.NationalityTypeId == "" ? "1" : data.NationalityTypeId;
    data.CountryStateTypeId =
      data.CountryStateTypeId == "" ? "1" : data.CountryStateTypeId;
    data.MaritalStatusTypeId =
      data.MaritalStatusTypeId == "" ? "1" : data.MaritalStatusTypeId;
    data.gender = "F";
    userService.register(data);
  };

  const nextPage = () => {
    setActivePage(activePage + 1);
  };

  const prevPage = () => {
    setActivePage(activePage - 1);
  };

  return (
    <section className="wrapper_register">
      <img
        className="bg_register"
        src={bgRegister}
        alt="Registrate en Kuimby Social"
      />
      <div className="box_header">
        <Link className="trans " to="/login">
          Ya tengo cuenta
        </Link>
      </div>
      <div className="container container_register">
        <div className="drawer_card_register">
          <div className="box_brand_register">
            <div>
              <h3 className="m-0 text fnt_bold c_primary line">
                Registro nuevo
              </h3>
            </div>
            <ImgCache
              src={companyLogo ? companyLogo : LogoCompany}
              alt="Logo oficial Kuimby Social"
            />
          </div>

          {/* <div className="bar_step_reg">
            <div className="box_item_step step_active">
              <span className="item_step">1</span>
            </div>
            <div
              className={`box_item_step ${activePage === 2 || activePage === 3 ? "step_active" : ""
                }`}
            >
              <span className="item_step">2</span>
            </div>
             <div
              className={`box_item_step ${
                activePage === 3 ? "step_active" : ""
              }`}
            >
              <span className="item_step">3</span>
            </div> 
          </div> */}

          <div className="box_form_register">
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                {
                  1: (
                    <Page1
                      register={register}
                      setValue={setValue}
                      errors={errors}
                    />
                  ),
                  /* 2: (
                     <Page2
                       register={register}
                       getValues={getValues}
                       errors={errors}
                     />
                   ),*/
                  // 3: (
                  //   <Page3
                  //     register={register}
                  //     getValues={getValues}
                  //     setValue={setValue}
                  //     errors={errors}
                  //   />
                  // ),
                }[activePage]
              }
              <hr />
              <div className="box_form_btns text-right mt-2">
                {/* {activePage > 1 && (
                  <button
                    type="button"
                    className="btn secundary "
                    onClick={prevPage}
                  >
                    <i className="fa-solid fa-angle-left"></i> Anterior
                  </button>
                )}

                <button type="submit" className="btn primary">
                  {activePage == 2 ? "Registrar" : "Siguiente"}
                </button> */}
                <button type="submit" className="btn primary">
                  Registrar
                </button>
              </div>
            </form>
          </div>
        </div>
        <Legals />
      </div>
    </section>
  );
}
