import React, { useEffect, useState } from "react";
import { ReactDOM } from "react";
import swal from "sweetalert";
import { categoryServiceService } from "../../services/categoryService.service";
import { useForm, useFormState } from "react-hook-form";
import "./registerServices.scss";
import { ValidacionEmail } from "../../utils/ValidacionMail"
import { ValidateName } from "../../utils/ValidateName"


export function FormPersonal(props) {
  let {
    categoryServiceId,
    contactImage,
    directoryContacts,
    fullName,
    location,
    subCategoryServiceId
  } = props.data;

  const activeTab3 = props.activeTab3;

  const [datos, setDatos] = useState({
    nombre: "",
    correo: "",
    contacto1: null,
    contacto1DirectoryContactId: null,
    contacto2: null,
    contacto2DirectoryContactId: null,
    categoria: "",
    subcategoria: "",
    location: "",
    imagen: "",
  });
  const [emailCorrecto, setErrorMail] = useState(false);
  const [nombreCorrecto, setErrorNombre] = useState(false);
  const [telefonoCorrecto, setErrorTel] = useState(false);
  const [band, setBand] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [contactArray, setContactArray] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
  } = useForm({});


  useEffect(() => {
    categoryServiceService
      .getCategoryCatalog()
      .then((categories) => {
        if (categories != null) {
          setCategoryFilter(categories);
        }
      });

  }, [])



  // function ValidacionEmail(email) {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(email);
  // }

  // function ValidateName(name) {
  //   const regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  //   return regexName.test(name);
  // }

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "correo":
        let error = false;
        if (event.target.value === "") {
          setErrorMail(error);
        } else {
          error = ValidacionEmail(event.target.value);

          setErrorMail(error);
        }

        break;

      case "nombre":
        let errorNombre = false;
        if (event.target.value === "") {
          setErrorNombre(errorNombre);
        } else {
          errorNombre = ValidateName(event.target.value);

          setErrorNombre(errorNombre);
        }
        break;

      case "contacto1":
        let errorContacto = event.target.value === "" ? false : true;
        event.target.value !== null ?
          setDatos({ ...datos, "contacto1": event.target.value }) :
          setErrorTel(errorContacto);
        break;

      case "contacto2":
        setDatos({ ...datos, "contacto2": event.target.value });
        break;

      case "categoria":
        if (event.target.value === "0") {
          setSubCategoryFilter([]);
          setDatos({ ...datos, "subcategoria": 0 });
        }
        else {
          setDatos({
            ...datos,
            "categoria": event.target.value,
          });
          categoryServiceService.getSubCategoriesCatalogByCatId(event.target.value, process.env.REACT_APP_COMPANY)
            .then((subs) => {
              setSubCategoryFilter(subs);
              setDatos({
                ...datos,
                "categoria": subs[0].categoryServiceId,
                "subcategoria": subs[0].subCategoryServiceId,
              });
            });
        }


        break;
      default:
        break;
    }

    if (event.target.name !== "imagen")
      setDatos({
        ...datos,
        [event.target.name]: event.target.value,
      });
  };

  useEffect(() => {
    let Data;
    if (props.register.personal.hasOwnProperty("nombre")) {
      let reg = props.register.personal;
      Data = {
        nombre: reg.nombre,
        correo: reg.correo,
        contacto1: reg.contacto1,
        contacto2: reg.contacto2,
        categoria: reg.categoria,
        subcategoria: reg.subcategoria,
        location: reg.location,
        imagen: reg.imagen,
      };
    } else {
      Data = {
        nombre: fullName,
        correo: "",
        contacto1:
          directoryContacts !== undefined && directoryContacts.length > 0
            ? directoryContacts[0].contactValue
            : null,
        contacto1DirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 0
            ? directoryContacts[0].directoryContactId
            : null,
        contacto2:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts[1].contactValue
            : null,
        contacto2DirectoryContactId:
          directoryContacts !== undefined && directoryContacts.length > 1
            ? directoryContacts[1].directoryContactId
            : null,
        categoria: categoryServiceId,
        subcategoria: subCategoryServiceId,
        location: location,
        imagen: contactImage,
      };
    }

    if (props.data)
      categoryServiceService.getSubCategoriesCatalogByCatId(props.data.categoryServiceId, process.env.REACT_APP_COMPANY)
        .then((subs) => {
          setSubCategoryFilter(subs);
          setDatos({
            ...datos,
            "categoria": props.data.categoryServiceId,
            "subcategoria": props.data.subCategoryServiceId,
            "nombre": props.data.fullName,
            "location": props.data.location,
            "contacto1": props.data.directoryContacts[0].contactValue,
            "contacto1DirectoryContactId": props.data.directoryContacts[0].directoryContactId,
            "correo": props.data.directoryContacts[1].contactValue,
            "contacto2DirectoryContactId": props.data.directoryContacts[1].directoryContactId,
            "imagen": props.data.contactImage,
          });
          setErrorMail(true);
          setErrorNombre(true);
          setErrorTel(true);
        });
    setDatos(Data);

    setErrorMail(Data.correo !== "" ? true : false);
    setErrorNombre(Data.nombre !== "" ? true : false);
    setErrorTel(Data.contacto1 !== "" ? true : false);

  }, [props.data]);






  function updateImage(e) {
    var file = e.target.files[0];
    props.setFile(file);
    var reader = new FileReader();
    //reader.Name = new Date().toString();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function () {
      setDatos({
        ...datos,
        imagen: [reader.result]
      })
    }
  }

  function deleteImg() {
    setDatos({ ...datos, imagen: "" });
    props.setFile(null);
    setBand(false);
    document.getElementById('file-upload').value = ""
  }

  function enviadatos(e) {
    if (emailCorrecto === false) {
      swal({ text: "Correo incorrecto, por favor revise.", icon: "error" });

    } else {
      if (nombreCorrecto === false) {
        swal({ text: "Nombre incorrecto, por favor revise.", icon: "error" });
      } else {
        if (telefonoCorrecto === false) {
          swal({
            text: "Teléfono incorrecto, por favor revise.",
            icon: "error",
          });
        } else {
          props.Datepersonal(datos, "personal");
          props.toggleView();
        }
      }

    }
  }




  return (
    <form onSubmit={handleSubmit(enviadatos)}>
      <div className="bar_step_reg">
        <div className="box_item_step step_active">
          <span className="item_step">1</span>
        </div>
        <div className="box_item_step ">
          <span className="item_step">2</span>
        </div>
        {activeTab3 == false ? "" : (
          <div className="box_item_step">
            <span className="item_step">3</span>
          </div>)}
      </div>
      <div className="content_shadows_form">
        <div className="row mt-3">
          <div className="col-md-12">
            <h6 className="text fnt_medium mb-3">Información personal</h6>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">Nombre del Servicio </label>
              <input
                defaultValue={datos["nombre"]}
                type="text"
                className="form-control form-control-sm"
                id={datos["nombre"]}
                placeholder="Nombre del Servicio"
                name="nombre"
                required={true}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">Correo electrónico</label>
              <input
                type="email"
                value={datos["correo"]}
                className="form-control form-control-sm"
                id={datos["correo"]}
                required={true}
                placeholder="Correo electrónico"
                name="correo"
                errormessage="Por favor, especifique una dirección de correo válida."
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">Teléfono de contacto</label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="1"
                required={true}
                placeholder="Teléfono de contacto"
                defaultValue={(datos.contacto1 !== null) ? datos.contacto1 : null}
                name="contacto1"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">
                Teléfono de contacto 2{" "}
                <small>
                  <i>(Opcional)</i>
                </small>
              </label>
              <input

                type="number"
                className="form-control form-control-sm"
                id="2"
                placeholder="Teléfono de contacto"
                name="contacto2"
                defaultValue={datos.contacto2 !== null ? datos.contacto2 : null}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="">Ubicación</label>
              <input
                defaultValue={datos["location"]}
                type="text"
                className="form-control form-control-sm"
                id={datos["location"]}
                placeholder="'Ciudad, Delegación' o 'Nacional'"
                name="location"
                required={true}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <h6 className="text fnt_medium mb-3">Información</h6>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="exampleFormControlSelect1">Categoría</label>

              <select
                className="form-control form-control-sm"
                id="exampleFormControlSelect1"
                name="categoria"
                value={datos["categoria"] || 0}
                required={true}
                onChange={handleInputChange}
              >
                <option value={0}>Seleccione categoría...</option>
                {categoryFilter && categoryFilter.map((categoryItem, index) => (
                  <option key={index} value={categoryItem.categoryServiceId}>{categoryItem.categoryName}</option>
                ))
                }

              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label for="exampleFormControlSelect1">Subcategoría</label>
              <select
                className="form-control form-control-sm"
                id="exampleFormControlSelect1"
                name="subcategoria"
                value={datos["subcategoria"] || 0}
                onChange={handleInputChange}
                required={true}
              >
                <option value={0}>Seleccione subcategoría...</option>
                {subCategoryFilter && subCategoryFilter.map((subCategoryItem, index) => (
                  <option key={index} value={subCategoryItem.subCategoryServiceId}>{subCategoryItem.subCategoryName}</option>
                ))
                }

              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group isHidde">
              <label htmlFor="">Imagen destacada</label>
              <input
                type="file"
                className=""
                id="file-upload"
                // defaultValue={datos["nombre"]}
                placeholder="Imagen destacada"
                accept="image/*"
                name="imagen"
                onChange={(e) => updateImage(e)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-center form-group" >
              <label htmlFor="">Previsualización</label>
              <div className="box_picture">
                {/* <img
                  src="https://i.ytimg.com/vi/3Q4ZTn5O3No/maxresdefault.jpg"
                  alt="imagen destacada"
                /> */}

                {(band || datos.imagen) &&
                  <>
                    <button type="button" onClick={(deleteImg)}
                    >
                      x
                    </button>

                    <img
                      alt="Imagen destacada"
                      src={datos.imagen}
                      onLoad={e => {
                        if (!props.loadPicture) return;
                        props.setLoadPicture(false);
                        let value = e.target.src;
                        let name = value.substring(value.lastIndexOf('/') + 1).replace("%", " ");
                        fetch(value)
                          .then(res => res.blob())
                          .then(blob => {
                            const file = new File([blob], name, blob)
                            props.setFile(file);
                          })
                      }}
                    ></img>
                  </>}
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <hr />
            <button
              type="submit"
              className="btn secundary"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
