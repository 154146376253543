import React from 'react'
import { HubItemProvider } from "../../components/hubItemProvider/hubItemProvider";
import { ToolDiscount } from '../../components/toolDiscount/toolDiscount'
import './discount.scss'

export function Discount() {
    return (
        <section className="wrapper_discount padd">
            <div className="mb-4 divtop">

                <div className="box_ttl_opt">
                    <h2 className="mb-0 text fnt_medium ">Descuentos Corporativos</h2>
                    <ToolDiscount />
                </div>

                <h4 className="ttls fw600">
                    <span className="ico_shop"><i className="fas fa-shopping-bag"></i></span>
                    <span className="pl-2 pr-2">Diversión y entretenimiento sin límite</span>
                </h4>

                <p className="font-italic pp">Encuentra tus marcas favoritas y sorpréndete </p>

            </div>
            <HubItemProvider />
        </section>
    )
}