import React, {useEffect, useState} from "react";
import { Link,   useParams, useRouteMatch, } from "react-router-dom";
import useInputForm from "../../components/CustonHooks/FormHook";

import "./jobBank.scss";
import { postulanteService } from "../../services/postulantJob.service";
import Swal from "sweetalert2";
import { backendService } from "../../services/backend.service";

export function ApplicantForm({ closeForm, job, user }) {
  // const [data, setArchivo] = useState({ archivo: null});
  const [archivo, setArchivo] = useState({
    archivo: null,
    archivoNombre: "",
    archivoURL: ""
  });
  
  let { id } = useParams();

  useEffect(() => {
    getFile(user.urlCurriculum);
  }, [])

  const onSave = (e) => {
  setArchivo({
    archivo: e.target.files[0],
    archivoNombre: e.target.files[0].name
  })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    if(archivo.archivo === null){
      formdata.append('Curriculum', null); 
    }else{
    formdata.append('Curriculum', archivo.archivo); 
    }

    formdata.append('JobId', id ); 

    postulanteService.agregarPostulante(formdata).then((result) => {
      if(result?.responseCode === "00"){
        //corregir lo de mensajes y mandar el correo.
        Swal.fire({ title: "¡Se envió tu postulación!", text: "Si resultas seleccionado para una entrevista, la empresa te notificará a través de la bandeja de avisos importantes", icon: "success", allowOutsideClick: false })
        .then(() => {
          closeForm();
        });
      }
      else{
        Swal.fire({ title: "Error al postularse", text: result.responseMessage, icon: "error", allowOutsideClick: false })
        .then(() => {
          closeForm();
        });
      }
    })
  }

  const getFile = async (url) => {
    if(url === null)
      return;
    let barra = url.lastIndexOf("/");
    let nombre = url.substring(barra + 1);

    let response = await fetch(url);
    let data = await response.blob();
    let file = new File([data], nombre);
    let container = new DataTransfer(); 
    container.items.add(file);
    document.querySelector('#archivo').files = container.files;

    setArchivo({
      archivo: file,
      archivoNombre: nombre
    })
  }
  
  

  // const { inputs, handleInputChange, handleSubmit, setInputs } = useInputForm(
  //   onSave,
  //   data
  // );

  return (
    <div className="wrapper_applicant_form">
      <div className="content_applicant_form">
        <div>
          <button className="goback trans" onClick={() => closeForm()}>
            <span className="ml-1">
              <ion-icon name="chevron-back-outline"></ion-icon> Regresar
            </span>
          </button>
        </div>
        <div className="sec_form_applicant card_shadows">
          <div className="content_application">
            <div className="info_business_applicant">
              <h6 className="text fnt_medium mb-1">{job.title}</h6>
              <p>{job.companyName} - {job.location}</p>
            </div>
            <form className="info_user_applicant">
              {user && 
                <fieldset>
                  <legend className="mb-0">Información del postulante</legend>
                  <h5 className="text fnt_medium mb-1">
                    {user?.firstName + " " + user?.middleName + " " + user?.lastName}
                  </h5>
                  <p>{user?.email}</p>
                  <p>{user?.phoneNumber}</p>
                  <p>{user.town}, {user.colony}. CP. {user.postalCode}</p>
                </fieldset>
              }
              <div className="mt-4">
                <p className="mb-1">
                  Adjunta tu currículum <i>(Formato .pdf o .docx)</i>
                </p>
                <div className="custom-file">
                  <input
                    type="file"
                    className="file-upload"
                    id="archivo"
                    lang="es"
                    name="archivo"
                    accept=".pdf"
                    // onChange={handleInputChange}
                    onChange={(x) => onSave(x)}
                    // required={true}
                  />
                  {archivo.archivoNombre === "" ? <label className="custom-file-label" htmlFor="archivo">
                    Seleccionar Archivo
                  </label> : <label className="custom-file-label" htmlFor="archivo">
                    {archivo.archivoNombre}
                  </label>}
                  {/* <label className="custom-file-label" for="archivo">
                    Seleccionar Archivo
                  </label> */}
                </div>
              </div>
              <div className="text-right mt-4">
              {/* to="/applicant-sent" */}
                <button className="btn primary" onClick={onSubmit}>
                  Continuar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
