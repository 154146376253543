import React, { useState } from "react";
import "./caringCommunity.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { ItemCaring } from "./itemCaring";
import { ToolDiscount } from "../../components/toolDiscount/toolDiscount";
import { AdvertisingSection } from "../../components/advertisingSection/advertisingSection";

export function CaringCommunity(props) {
  return (
    <section className="wrapper_surveys wrapper_caring padd">
      <div className="content_surveys w-100">
        <div className="row align-items-center mb-2">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">Fundaciones</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date.now()}
              </Moment>
              {/* <ToolDiscount isCaringCommunity={true} /> */}
            </span>
          </div>
        </div>
        <p className="drawer_reg_sex">
          Sabemos que aún hay muchas causas que se pueden apoyar.
          <br />
          ¡Ayúdanos ayudar!
        </p>

        <AdvertisingSection HolType={6} />

        {/* <div className="content_ttls">
          <div className="minw">
            <h4 className="text fnt_medium ttl mb-3">
              Únete y aporta. Juntos haremos más
            </h4>
            <p className="text fnt_medium">
              Gracias a gente como tú, hacemos más. Contribuye al
              fortalecimiento social y el bien común.
            </p>
          </div>
        </div> */}
        <ItemCaring />
      </div>
    </section>
  );
}
