import React, { useState, useEffect } from 'react';
import { SelectCustom } from '../../components/Helpers/SelectCustom';
import { helperService } from '../../services/helper.service';
import { userProfileService } from '../../services/backoffice/userProfile.service';
import useInputSingleForm from '../../components/CustonHooks/inputFormHook'
import { FormGroup, Label } from "reactstrap";
import { Pagination } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import MultiSelect from "react-multi-select-component";

import 'rsuite/dist/styles/rsuite-default.css'

export function SelectUsers({ selectUser, title, filter, usersFilter }) {
    let configInit = { Departments: [], JobRoleId: 0 }
    const [departaments, setDepartaments] = useState([]);
    const [userSelec, setUserSelec] = useState([]);
    const [dataUsers, setDataUsers] = useState({ list: [], totalPages: 1, totalCount: 0, pageIndex: 1 });
    const [dataAllUsers, setDataAllUsers] = useState([]);
    const { inputs, handleInputChange, setInputs } = useInputSingleForm(null, configInit);

    useEffect(() => {
        getDepartaments();
    }, []);

    useEffect(() => {
        handleOnSearch(1);
    }, [usersFilter]);


    const handleOnSearch = (pageIndex) => {
        if (inputs.Departments.length > 0) {
            let model = {
                Departments: inputs.Departments.map(e => e.value).join(","), JobRoleId: parseInt(inputs.JobRoleId === '' ? 0 : inputs.JobRoleId),
                PageIndex: pageIndex,
                PageCount: 20,
            };

            userProfileService.getUsersByFilterPage(model)
                .then((result) => {
                    if (result) {
                        if (filter) {
                            let users = result;
                            if (usersFilter.length > 0) {
                                usersFilter.map((user) => {
                                    users.list = users.list.filter((item) => item.userProfileId != user.id);
                                });

                                setDataUsers({ ...dataUsers, list: users.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex });
                            }
                            else {
                                setDataUsers({ ...dataUsers, list: users.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex });
                            }
                            let datauser = { list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex }
                            if (dataAllUsers.length > 0) {
                                let datauser = { list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex }
                                JointUser(datauser, dataAllUsers);
                            } else if (userSelec.length > 0) { setCheck(datauser); }
                        }
                        else {
                            setDataUsers({ ...dataUsers, list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex });
                            let datauser = { list: result.list, totalCount: result.totalCount, totalPages: result.totalPages, pageIndex: pageIndex }
                            if (dataAllUsers.length > 0) {
                                JointUser(datauser, dataAllUsers);
                            } else if (userSelec.length > 0) { setCheck(datauser); }
                        }
                    }
                });
        }
    }

    const handleSelectUser = (event, data) => {
        let localUsers = [];
        data.check = event.target.checked;
        if (data.check) {
            localUsers = [...userSelec, { id: data.userProfileId, name: `${data.middleName} ${data.lastName} ${data.firstName}`, department: data.department?.departmentName }];
            setUserSelec(localUsers);
        } else {
            localUsers = userSelec.filter((item) => item.id != data.userProfileId);
            setUserSelec(localUsers);
        }
        selectUser(localUsers);
        if (dataAllUsers.length > 0) {
            let itemAll = dataAllUsers.find((x) => x.userProfileId == data.userProfileId);
            itemAll.check = data.check;
        }
        event.stopPropagation();
    };

    const JointUser = (userData, usersAll) => {
        userData.list.map(data => {
            let itemAll = usersAll.find((item) => item.userProfileId == data.userProfileId);
            data.check = itemAll.check;
        });

        setDataUsers({ ...dataUsers, list: userData.list, totalCount: userData.totalCount, totalPages: userData.totalPages, pageIndex: userData.pageIndex });
    }

    const setCheck = (userData) => {
        userData.list.map(data => {
            let itemAll = userSelec.find((item) => item.id == data.userProfileId);
            if (itemAll) {
                data.check = true;
            }
        });

        setDataUsers({ ...dataUsers, list: userData.list, totalCount: userData.totalCount, totalPages: userData.totalPages, pageIndex: userData.pageIndex });
    }

    const handleSelectUserAll = (event) => {
        setUserSelec([]);
        let localUsers = [];
        let status = event.target.checked;
        dataUsers.list.map(data => {
            data.check = event.target.checked;
        });

        if (dataAllUsers.length === 0) {
            let model = {
                Departments: inputs.Departments.map(e => e.value).join(","), JobRoleId: parseInt(inputs.JobRoleId === '' ? 0 : inputs.JobRoleId)
            };

            userProfileService.getUsersByFilter(model)
                .then((result) => {
                    if (result) {
                        let users = result;
                        users.map(data => {
                            if (status && !data.check) {
                                userSelec.push({ id: data.userProfileId, name: `${data.middleName} ${data.lastName} ${data.firstName}`, department: data.department?.departmentName })
                                setUserSelec([...userSelec]);
                                localUsers = userSelec;
                            }

                            data.check = status;
                        });
                        selectUser(localUsers);
                        setDataAllUsers(users);
                    }
                });
        } else {
            let users = dataAllUsers;
            users.map(data => {
                if (status && !data.check) {
                    userSelec.push({ id: data.userProfileId, name: `${data.middleName} ${data.lastName} ${data.firstName}`, department: data.department?.departmentName })
                    setUserSelec([...userSelec]);
                    localUsers = userSelec;
                }

                data.check = status;
            });
            selectUser(localUsers);
            setDataAllUsers(users);
            JointUser(dataUsers, users);
        }

    }

    const handleSelectPage = (pageIndex) => {
        handleOnSearch(pageIndex);
    }

    const getDepartaments = () => {
        helperService.getGenericCatalog("/backoffice/Department")
            .then((result) => {
                if (result)
                    setDepartaments(result.map(item => ({ label: item.departmentName, value: item.departmentId })))
                else
                    setDepartaments([]);
            });
    }

    return (
        <div className="row align-items-center mt-4" >
            <div className="col-12">
                <h5 className="text fnt_medium">{title}</h5>
                <hr />
            </div>
            <div className="col-md-4">
                <FormGroup>
                    <Label for="DepartmentId">Grupos</Label>
                    <MultiSelect
                        name="DepartmentId"
                        className='form-control'
                        options={departaments}
                        value={inputs.Departments}
                        onChange={(e) => setInputs({ ...inputs, Departments: e })}
                        overrideStrings={{
                            "allItemsAreSelected": "Todos los grupos.",
                            "clearSearch": "Borrar búsqueda",
                            "noOptions": "Sin opciones",
                            "search": "Buscar",
                            "selectAll": "Seleccionar todo",
                            "selectSomeItems": "Selecciona una opción"
                        }}
                    />
                </FormGroup>
            </div>
            <div className="col-md-4">
                <FormGroup >
                    <Label for="JobRoleId">Categorías</Label>
                    <SelectCustom selectName="JobRoleId" handleOnChange={handleInputChange} selectValue={inputs.JobRoleId}
                        method="JobRole" textField="name" valueField="jobRoleId" defaultOption="Todos" />
                </FormGroup>
            </div>
            <div className="col-md-4">
                <button type="button" className="btn secundary minimum" onClick={() => handleOnSearch(1)}>Consultar</button>
            </div>

            <div className="col-12">
                <div className="table-responsive mt-4">
                    <table className="wrapper_table table table-sm table-hover">
                        <thead>
                            <tr>
                                <th className="text fnt_medium">No. Usuario</th>
                                <th className="text fnt_medium">Nombre de Usuario</th>
                                <th className="text fnt_medium">Grupo</th>
                                <th className="text fnt_medium text-center">
                                    {dataUsers.list.length > 0 ?
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" id="checkboxAll" className="custom-control-input" onClick={handleSelectUserAll} />
                                            <label className="custom-control-label" htmlFor="checkboxAll">Seleccionar</label>
                                        </div> : "Seleccionar"}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataUsers.list.length > 0 ? dataUsers.list.map(item => (
                                <tr key={item.userProfileId}>
                                    <td>{item.employeeNo}</td>
                                    <td>{item.middleName} {item.lastName} {item.firstName}</td>
                                    <td>{item.department?.departmentName}</td>
                                    <td className="text-center">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id={`check-${item.userProfileId}`} onClick={(event) => handleSelectUser(event, item)} checked={item.check} />
                                            <label className="custom-control-label" htmlFor={`check-${item.userProfileId}`}> </label>
                                        </div>
                                    </td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </table>
                    {dataUsers.list.length > 0 ?
                        <Pagination prev last next first size="lg" ellipsis maxButtons={10} boundaryLinks pages={dataUsers.totalPages} activePage={dataUsers.pageIndex} onSelect={handleSelectPage} />
                        : ""}
                </div>
            </div>
        </div>

    );
}
