import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { adminPolicyService } from '../../services/adminPolicy.service';
import Moment from 'react-moment'

const SearchAdminPolicy = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        debugger;
        adminPolicyService.deletePolicyProcess(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        adminPolicyService.getAll()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">
                <thead>
                    <tr>
                        <th className="text fnt_medium min_width">Título del reglamento</th>
                        <th className="text fnt_medium min_width">Típo</th>
                        <th className="text fnt_medium text-center">Descripción</th>
                        <th className="text fnt_medium">URL</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.policyId } >
                            <td><span className="link_data" onClick={() => props.handleRowSelect(item)}>{item.title }</span> </td>
                            <td className="text-left">{item.policyType }</td>
                            <td className="text-center">{item.description }</td>
                            <td>{item.url }</td>
                            {item.active ?
                                <td>
                                    <span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                    <span className="icon_tables" onClick={() => props.handleRowSelect(item)}>
                                        <i className="far fa-edit"></i>
                                    </span>
                                </td> 
                                    : null}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

});

export default SearchAdminPolicy;
