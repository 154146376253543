import React, { useEffect, useState } from 'react';
import { useLegalDocumentService } from '../../services/legalDocument.service';

export function Legals() {
  const [legalDocsData, setLegalDocsData] = useState({ legalDocs: [] });

  async function getLegalDocuments() {
    useLegalDocumentService.getLegalDocuments().then((result) => {
        if (result) {
            setLegalDocsData({ legalDocs: result });
        }
    });
  }

  useEffect(() => {
    getLegalDocuments();
  }, []);

  return (
    <div className="nav_legals">
      {legalDocsData.legalDocs.map(legalDoc => (
          <>
              <a target="_blank" href={legalDoc.file}>
                  {legalDoc.legalDocumentType}
              </a>
              <br/>
          </>
      ))}
    </div>
  );
}
