import React, { useState } from 'react';
import { EditCommunity } from './editCommunity'
import { ListCommunity } from './listCommunity'

export function CaringCommunity() {
    const [showEdit, setShowEdit] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [selectedCommunity, setSelectedCommunity] = useState();

    const handleOnCommunitySelected = (community) => {
        setSelectedCommunity(community);
        setShowEdit(true);
    };
    
    const handleOnNewCommunity = () => {
        setSelectedCommunity(null);
        setShowEdit(true);
    };
    const handleOnCancelEdit = () => {
        setShowEdit(false);
    }
    const handleOnSaveEdit = (result) => {
        setRefresh(prev => prev + 1);
        if (result)
            setShowEdit(false);
    }

    return (
        <section className="wrapper_control padd">
			<div className="mb-4 divtop ">
				<div className="box_ttl_opt no_reverse">
					<h2 className="mb-0 text fnt_medium">Fundaciones</h2>
				</div>
			</div>
            <div className='card shadows p-3'>
                {showEdit && <EditCommunity currentCommunity={selectedCommunity} onCancelEdit={handleOnCancelEdit} onSave={handleOnSaveEdit} />}
                {!showEdit && <ListCommunity onNewClicked={handleOnNewCommunity} onSelectCommunity={handleOnCommunitySelected} refresh={refresh} />}
            </div>
        </section>
    )
}