import React, { useEffect, useState } from 'react';

import { useCaringCommunity } from '../../services/caringCommunity.service';

export function ListCommunity(props){
    const [CommunitiesData, setCommunitiesData] = useState([]);

    const handleDelete = (data) => {
        useCaringCommunity.deleteCommunity(data.caringCommunityId).then(() => {
            getCommunities();
        });
    };

    useEffect(() => {
        getCommunities();
    }, [props.refresh])

    async function getCommunities() {
        useCaringCommunity.getCommunities(process.env.REACT_APP_COMPANY).then((result) => {
            setCommunitiesData(result);
        });
    }

    async function onReactivateCommunity(data) {
        data.active = true;
        let file = await getFileImage(data.image);

        let formData = new FormData();
        formData.append("CaringCommunityId", data.caringCommunityId);
        formData.append("Name", data.name);
        formData.append("Description", data.description);
        formData.append("CompanyId", data.companyId);
        formData.append("Ligue1", data.ligue1);
        formData.append("Ligue2", data.ligue2);
        formData.append("Image", file);
        formData.append("Active", true);
        formData.append("createDate", data.createDate);

        useCaringCommunity.updateCommunity(formData).then(() => {
            getCommunities();
        });
    }

    const getFileImage = async (url) => {
        let name = url.substring(url.lastIndexOf('/') + 1).replace("%", " ");
        let file = await fetch(url)
            .then(res => res.blob())
            .then(blob => {
            const file = new File([blob], name, blob)
             return file;
            })
        return file;
    }

    return (
        <div>
            <div className="mb-4 mt-4 divtop ">
                <div className="box_ttl_opt no_reverse">
                    <h3 className="mb-0">Lista de comunidades solidarias</h3>
                    <div className="box_actions ">
                        <button type="button" className="btn secundary mr-2" onClick={props.onNewClicked}><i className="fas fa-plus"></i> Agregar comunidad</button>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="wrapper_table table table-sm table-hover ">
                    <thead>
                        <tr>
                            <th className="text fnt_medium">Imagen</th>
                            <th className="text fnt_medium">Título</th>
                            <th className="text fnt_medium">Fecha de creación</th>
                            <th className="text fnt_medium">Estatus</th>
                            <th className="text fnt_medium">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CommunitiesData.map(community => (
                            <tr key={community.caringCommunityId} >
                                <td>
                                    <img 
                                        src={community.image} 
                                        style={{ width: '60px' }} 
                                        alt="Imagen de Fundaciones"
                                    ></img>
                                </td>
                                <td>{community.name}</td>
                                <td>{community.createDate}</td>
                                <td>{community.active ? "Activo" : "Inactivo"}</td>
                                <td>
                                    {community.active &&
                                        <>
                                        <span className="icon_tables" onClick={() => props.onSelectCommunity(community)} >
                                            <i className="fas fa-pen"></i>
                                        </span>
                                        <span className="icon_tables" onClick={() => handleDelete(community)}>
                                            <i className="far fa-trash-alt"></i>
                                        </span>
                                        </>
                                    }
                                    {!community.active && 
                                    <span className="icon_tables" onClick={() => onReactivateCommunity(community)}>
                                        <i className="fas fa-check-circle"></i>
                                    </span>
                                    }

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    )
}