import React, { useRef, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { adminPolicyService } from "../../services/adminPolicy.service";
import { storageService } from "../../services/storage.service";
import SearchAdminPolicy from "./searchAdminPolicy";
import { AdminPolicycss } from "./policy.scss";
import moment from "moment";
import swal from "sweetalert";
import { ToolDiscount } from './../../components/toolDiscount/toolDiscount';

export function AdminPolicy() {
  const [templateShow, setTemplateShow] = useState("1");
  const [localAttachments, setLocalAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [localfiles, setLocalFiles] = useState([]);
  const [userSelec, setUserSelec] = useState([]);
  const [ErrorDescription, setErrorDescription] = useState(false);
  const [ErrorTitle, setErrorTitle] = useState(false);

  const gridRef = useRef();
  const policyType = ["Política", "Proceso"];

  const [policy, setPolicy] = useState({
    PolicyId: 0,
    Title: "",
    Url: "",
    PolicyType: "Elija el Tipo",
    Description: "",
  });

  const handleIconTrash = (e) => {
    // debugger;
    const { style } = e.target;
    if (style.color == "red")
      style.color = "black";
    else
      style.color = "red";
  }

  const toggleTemplate = (sec) => {
    if (templateShow !== sec) setTemplateShow(sec);
  };

  async function savePolicy(item) {
    adminPolicyService.create(item, localfiles)
      .then((result) => { })
      .then((result) => {
        toggleTemplate("1");
        gridRef.current.refreshDataGrid();
      });
  }

  async function updatePolicy(item) {
    adminPolicyService.update(item, localfiles)
      .then((result) => { })
      .then((result) => {
        toggleTemplate("1");
        gridRef.current.refreshDataGrid();
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Description") {
      //if value is more at 500 return error
      if (value.length > 100) {
        setErrorDescription(true);
        return;
      }
      else {
        if (ErrorDescription)
          setErrorDescription(false);
      }
    }
    if (name === "Title") {
      //if value is more at 500 return error
      if (value.length > 30) {
        setErrorTitle(true);
        return;
      }
      else {
        if (ErrorTitle)
          setErrorTitle(false);
      }
    }
    setPolicy({ ...policy, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // debugger;
    if (policy.PolicyType == "Elija el Tipo") {
      swal({ text: "Debes elegir si es Política o Proceso", icon: "error" })
      return null;
    }

    if (userSelec.length > 0) {
      let itemSave = {
        PolicyId: policy.PolicyId,
        Title: policy.Title,
        Url: policy.Url,
        PolicyType: policy.PolicyType,
        Description: policy.Description,
        ListUser: userSelec
      };

      console.log(itemSave);
      if (parseInt(policy.PolicyId) === 0) {
        savePolicy(itemSave);
        console.log('Guarda')
      }
      else {
        updatePolicy(itemSave);
      }
    } else {
      swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    }

    setUserSelec([]);
    setImages([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  }

  const handleOnChangeAttach = (attachments) => {
    setLocalAttachments(attachments);
  };

  const handleDeleteImage = (event) => {
    storageService
      .deleteStorage({
        EntityId: images[0].entityId,
        EntityTypeId: images[0].entityTypeId,
        Sequence: images[0].sequence,
      })
      .then((result) => {
        setImages([]);
      });
    event.stopPropagation();
  };

  const handleOnChangeFile = (files) => {
    // debugger;
    setLocalFiles(files);
    console.log(localfiles);
  };

  const handleRowSelect = (gridItem) => {
    // debugger;
    console.log(gridItem);
    toggleTemplate("2");
    setPolicy({
      PolicyId: gridItem.policyId,
      Title: gridItem.title,
      Url: gridItem.url,
      PolicyType: gridItem.policyType,
      Description: gridItem.description,
    });

    adminPolicyService
      .getPolicyImageById(gridItem.policyId)
      .then((result) => {
        setImages(result.files);
      });

  };

  const handleOnNew = () => {
    // debugger;
    let policyNew = {
      PolicyId: 0,
      Title: "",
      Url: "",
      PolicyType: "Elija el Tipo",
      Description: "",
    };

    setPolicy(policyNew);
    toggleTemplate("2")
    setUserSelec([]);
    setImages([]);
    setLocalFiles([]);
    setLocalAttachments([]);
  };

  return (
    <>
      <section className="wrapper_post_admin padd">
        <div className="mb-4 divtop ">
          <div className="box_ttl_opt no_reverse mb-0">
            <h2 className="mb-0 text fnt_medium">Reglamentos</h2>
          </div>
          <div className="box_ttl_opt no_reverse">
            <h6 className="mb-0 text-muted">Administrador</h6>
          </div>
        </div>
        {
          {
            1: (
              <>
                <div className="mb-4 mt-4 divtop ">
                  <div className="box_ttl_opt no_reverse">
                    <h4 className="mb-0 text fnt_medium">
                      Lista de Reglamentos
                    </h4>
                    <div className="box_actions ">
                      <button
                        type="button"
                        className="btn secundary mr-2"
                        onClick={handleOnNew}
                      >
                        <i className="fas fa-plus"></i> Agregar Reglamentos
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <SearchAdminPolicy
                    handleRowSelect={handleRowSelect}
                    ref={gridRef}
                  />
                </div>
              </>
            ),
            2: (
              <>
                <span className="back_go" onClick={() => toggleTemplate("1")}>
                  <i className="fas fa-chevron-left"></i> Atrás
                </span>
                <form className="card shadows p-3 mt-3" onSubmit={handleSubmit}>
                  <fieldset className="mt-4 mb-3">
                    <legend className="text fnt_medium">
                      Nuevo Reglamento / Editar Reglamento
                    </legend>
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="Title">Título del Reglamento</Label>
                          <Input
                            required
                            type="text"
                            name="Title"
                            placeholder="Título del Reglamento"
                            onChange={handleChange}
                            value={policy.Title}
                            min="1"
                            max="30"
                            invalid={ErrorTitle ? true : false}
                          />
                          {ErrorTitle && (
                            <div className="invalid-feedback">
                              El campo debe tener entre 1 y 30 caracteres
                            </div>
                          )}

                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="Url">URL de archivo</Label>
                          <Input
                            type="text"
                            name="Url"
                            placeholder="URL del archivo"
                            onChange={handleChange}
                            value={policy.Url}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 mb-3">
                        <div className="form-group">
                          <FormGroup>
                            {images !== null && images.length > 0 ? (
                              <div className="post_img ">
                                <img
                                  className="img-fluid-policy"
                                  src={`data:image/jpeg;base64,${images[0].file}`}
                                  style={{ width: "150px" }}
                                />
                                <span className="icon_tables" onClick={(event) => handleDeleteImage(event)}>
                                  <i className="far fa-trash-alt delete_image_policy" onPointerLeave={(event) => handleIconTrash(event)} onPointerEnter={(event) => handleIconTrash(event)}></i>
                                </span>
                              </div>
                            ) : null}
                          </FormGroup>
                          {images == null || images.length === 0 ? (
                            <GeneralUpload
                              accept="image/*"
                              isMultiple="false"
                              TitleLabel={"Imagen"}
                              handleOnChangeFiles={handleOnChangeFile}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="PolicyType">Tipo</Label>
                          <select className="form-control" name="PolicyType" onChange={handleChange}
                            selectvalue={policy.PolicyType} value={policy.PolicyType} required={true}>
                            <option value="Elija el Tipo">{"Elija el Tipo"}</option>
                            {
                              policyType.map(item => (
                                <option key={item} value={item} invalid={true}>
                                  {item}
                                </option>
                              )
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="form-group">
                          <Label for="Description">Descripción</Label>
                          <Input
                            className="input-description-policy"
                            required
                            type="textarea"
                            name="Description"
                            placeholder="Descripción del Reglamento"
                            onChange={handleChange}
                            value={policy.Description}
                            min="1"
                            max="100"
                            invalid={ErrorDescription ? true : false}
                          />
                          {ErrorDescription && (
                            <div className="invalid-feedback">
                              El campo debe tener entre 1 y 100 caracteres
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    {policy.PolicyId > 0 ? (
                      <AudienceUsers
                        targetId={policy.PolicyId}
                        eventType={"Proceso"}
                        selectUser={setUserSelec}
                      />
                    ) : (
                      <SelectUsers
                        selectUser={setUserSelec}
                        title={"Evento dirigido a:"} />
                    )}
                  </fieldset>
                  <div className="text-rigNameht mt-2 mb-2">
                    <button className="btn ml-1 mrName-1 secundary" type="button" onClick={() => toggleTemplate("1")}>
                      Regresar
                    </button>
                    <button className="btn ml-1 Namemr-1 primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </form>
                <br />
                <br />
                <br />
              </>
            ),
          }[templateShow]
        }
      </section>
    </>
  );
}
