import React, { useState } from "react";
import { Link } from "react-router-dom";
import bgRegister from "../../assets/imgs/bg_register.jpg";
import LogoCompany from "../../assets/imgs/LogoLigaPremier.png";
import "./register.scss";
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache"

export function MembershipPayment(props) {
    const companyLogo = useSelector(state => state.userSettings.companyLogo);
    return (
        <section className="wrapper_register">
            <img
                className="bg_register"
                src={bgRegister}
                alt="Resgistrate en Kuimby Social"
            />
            <div className="container container_register">
                <div className="drawer_card_register">
                    <div className="box_brand_register">
                        <div>
                            <h5 className="m-0 text c_primary">Pago de</h5>
                            <h2 className="m-0 text fnt_bold c_primary">Membresía </h2>
                        </div>
                        <ImgCache src={companyLogo ? companyLogo : LogoCompany} alt="Logo oficial Kuimby Social" />
                    </div>
                    <hr />

                    {props.location.pathname == "/error-membership"
                        ? <div className="box_form_register pay_cancel">
                            <div className="box_form_membership">
                                <div className="row">
                                    <div className="col-md-12 mt-2">
                                        <div className=" text-center box-ico-pay cancel">
                                            <i className="fas fa-info-circle"></i>
                                        </div>
                                        <h2 className="ttl_membership text-center text fnt_bold line">
                                            ¡Ocurrió un problema con el pago, ha sido cancelada o rechazada por el banco!
                                    </h2>
                                        <p className="paragraph ">
                                            Puedes actualizar tu método de pago e intentarlo de nuevo.
                                    </p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="box_form_btns text-center mt-2">
                                <Link to="/login" className="btn primary">
                                    Iniciar sesión
                            </Link>
                            </div>
                        </div>
                        : <div className="box_form_register pay_success">
                            <div className="box_form_membership">
                                <div className="row">
                                    <div className="col-md-12 mt-2">
                                        <div className=" text-center box-ico-pay success">
                                            <i className="far fa-check-circle"></i>
                                        </div>
                                        <h2 className="ttl_membership text-center text fnt_bold line">
                                            ¡Pago exitoso!
                                    </h2>
                                    {/*    <p className="paragraph ">*/}
                                    {/*        Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic*/}
                                    {/*        quidem dignissimos ea officia nam reprehenderit similique,*/}
                                    {/*        explicabo eaque, aspernatur tempore perferendis!*/}
                                    {/*</p>*/}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="box_form_btns text-center mt-2">
                                <Link to="/login" className="btn primary">
                                    Iniciar sesión
                            </Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}
